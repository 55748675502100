import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Stack, Typography, Skeleton } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { SwitchAccountOutlined } from '@mui/icons-material';
import {
  useGetIntegrationsQuery,
  useGetIntegrationsForUserQuery,
  useLazyGetIntegrationAuthenticationUrlQuery,
  useGetIntegrationsForCustomerQuery,
} from '../../../services/integrations/integrationsApi';
import { resetIntegration } from '../../../redux/slices/integrationsSlice';
import { setIntegrationSearch } from '../../../redux/slices/globalDialogueSlice';
import { setToast } from '../../../redux/slices/globalToastSlice';
import { parseQueryStringToObject } from './helpers';

const SearchIntegrationButton = ({ customerId }) => {
  const dispatch = useDispatch();

  const {
    integratedCase: { id: matterId, claimNumber },
  } = useSelector((state) => state.Integrations);

  const { data: integrations, isLoading: isIntegrationsLoading } = useGetIntegrationsQuery();

  const { data: userIntegrations, isLoading: isUserIntegrationsLoading } = useGetIntegrationsForUserQuery();

  const { data: customerIntegrations, isLoading: isCustomerIntegrationsLoading } = useGetIntegrationsForCustomerQuery({
    customerId,
  });

  const { integrationId } = customerIntegrations?.integrations[0] ?? {};

  const integrationMetadata = integrations?.find((integration) => integration.integrationId === integrationId) ?? {};
  const { displayName: integrationName } = integrationMetadata;

  const [getIntegrationAuthUrl] = useLazyGetIntegrationAuthenticationUrlQuery();

  useEffect(() => {
    const queryParams = parseQueryStringToObject(window.location.search);
    if (!isIntegrationsLoading && queryParams.authSuccess) {
      dispatch(setIntegrationSearch({ open: true, data: { integrationName, integrationId } }));
      dispatch(setToast({ isOpen: true, message: `Successfully signed into ${integrationName}` }));
    }

    return () => {
      dispatch(resetIntegration());
    };
  }, [isIntegrationsLoading, integrationName, dispatch]);

  const handleSearch = async () => {
    const userIntegration =
      userIntegrations?.find((userIntegration) => userIntegration.integrationId === integrationId);

    if (userIntegration && !userIntegration?.isExpired) {
      dispatch(setIntegrationSearch({ open: true, data: { integrationName, integrationId } }));
    } else {
      // strip url so query params are not included in the redirect url
      const { origin, pathname } = new URL(window.location.href);
      const urlWithoutQueryString = origin + pathname;

      const requestBody = {
        redirectState: { form: 'compose', integration: integrationId },
        redirectUrl: urlWithoutQueryString,
      };
      const { data: authUrlData } = await getIntegrationAuthUrl({ integrationId, payload: requestBody });

      authUrlData && window.location.replace(authUrlData?.authenticationUrl);
    }
  };

  const isLoading = isIntegrationsLoading && isUserIntegrationsLoading && isCustomerIntegrationsLoading;

  return isLoading ? (
    <Stack spacing={2}>
      <Skeleton variant="text" width={150} height={24} />
      <Skeleton variant="rounded" width={130} height={35} />
    </Stack>
  ) : (
    <Stack spacing={1} justifyContent="end">
      <Typography variant="body1" color="primary">
        Select demand from {integrationName}
      </Typography>

      {matterId ? (
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="body1">{claimNumber}</Typography>
          <Button
            color="secondary"
            startIcon={<SwitchAccountOutlined sx={{ fontSize: '1.2rem' }} />}
            onClick={handleSearch}
          >
            Select different demand in {integrationName}
          </Button>
        </Stack>
      ) : (
        <Box>
          <Button variant="outlined" color="secondary" startIcon={<SearchOutlinedIcon />} onClick={handleSearch}>
            Search {integrationName}
          </Button>
        </Box>
      )}
    </Stack>
  );
};

export default SearchIntegrationButton;
