import { createSlice } from "@reduxjs/toolkit";

const IntegrationsSlice = createSlice({
    name: "Integrations",
    initialState: {
        addIntegrationModalOpen: false,
        integrationDocumentSelectionModalOpen: false,
        integratedCase: {},
        integrationData: {},
    },
    reducers: {
        setAddIntegrationModalOpen: (state, action) => {
            state.addIntegrationModalOpen = action.payload;
        },
        setIntegrationDocumentSelectionModalOpen: (state, action) => {
            state.integrationDocumentSelectionModalOpen = action.payload;
        },
        setIntegratedCase: (state, action) => {
            state.integratedCase = action.payload;
        },
        setIntegrationData: (state, action) => {
            state.integrationData = action.payload;
        },
        resetIntegration: (state) => {
            state.integratedCase = {};
            state.integrationData = {};
        },
    },
    extraReducers: (builder) => {
        //
    }
});

export const {
    setAddIntegrationModalOpen,
    setIntegrationDocumentSelectionModalOpen,
    setIntegratedCase,
    setIntegrationData,
    resetIntegration,
} = IntegrationsSlice.actions;

export default IntegrationsSlice.reducer;

