import React, { useEffect, useState } from 'react';
import { Button, Stack, Typography, IconButton, Box, Tooltip } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { CloudUploadOutlined, DeleteOutlined, Computer } from '@mui/icons-material';
import { setDocumentUploaderList } from '../redux/slices/demandDomSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getIntegrationLogoUrl } from '../CustomerManagement/Integrations/Helpers';
import { setIntegrationDocumentSelectionModalOpen } from '../redux/slices/integrationsSlice';
import CustomMuiDialogWrapper from '../CommonComponents/CustomMuiDialogWrapper';
import IntegrationsDocumentSelectionModal from '../CustomerManagement/Integrations/DocumentSelectionModal';
import { useGetIntegrationsQuery } from '../services/integrations/integrationsApi';
import { getDoucumentSourceDisplayName } from "../common-document";

const ACCEPTED_FILE_TYPES = ".pdf,.jpg,.jpeg,.png,.tif,.tiff,.doc,.docx";
const MAX_FILE_SIZE = 157286400; // 150MB
const ROW_HEIGHT = 52; // MUI DataGrid default

const DocumentUploader = ({ heightOffset = '128' }) => {
    const [isDragging, setIsDragging] = useState(false);
    const dispatch = useDispatch();
    const { documentUploaderList } = useSelector((state) => state.DemandDom);
    const { integrationDocumentSelectionModalOpen } = useSelector((state) => state.Integrations);
    const { data: integrations, isLoading: isIntegrationsLoading } = useGetIntegrationsQuery();

    const { integratedCase, integrationData } = useSelector((state) => state.Integrations);
    const hasLinkedCase = Boolean(integratedCase.id);

    useEffect(() => {
        return () => dispatch(setDocumentUploaderList([]));
    }, [dispatch]);

    const fileListWithId = documentUploaderList.map(file => ({
        ...file,
        id: file.name,
        name: file.name
    }));

    const handleInputChange = (e) => {

        const files = [...e.target.files].map(file => ({
            id: file.name,
            name: file.name,
            size: file.size,
            type: file.type,
            lastModified: file.lastModified,
            isExternalFile: false,
            error: file.size > MAX_FILE_SIZE ? 'File size cannot exceed 150MB' : null
        }));

        const newFileList = [...documentUploaderList, ...files];
        const uniqueFiles = newFileList.filter((file, index, self) =>
            index === self.findIndex(t => t.name === file.name)
        );

        dispatch(setDocumentUploaderList(uniqueFiles));
    };

    const dragHandlers = {
        onDragEnter: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(true);
        },
        onDragLeave: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(false);
        },
        onDragOver: (e) => {
            e.preventDefault();
            e.stopPropagation();
        },
        onDrop: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setIsDragging(false);

            const files = [...e.dataTransfer.files];
            handleInputChange({ target: { files } });
        }
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            width: 360,
            disableColumnMenu: true,
            disableColumnFilter: true,
            disableColumnSelector: true,
            sortable: true,
            renderCell: (params) => (
                <Stack spacing={1}>
                    <Typography variant="body1">{params.row.name}</Typography>
                    {params.row.error && (
                        <Typography variant="caption" color="error">{params.row.error}</Typography>
                    )}
                </Stack>
            ),
        },
        {
            field: "sourceSystemId",
            headerName: "Source",
            width: 120,
            disableColumnMenu: true,
            disableColumnFilter: true,
            disableColumnSelector: true,
            minWidth: 100,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params) => (
                <Stack>
                    <Typography variant="tableP1">
                        {!isIntegrationsLoading ?
                            getDoucumentSourceDisplayName(params.row.sourceSystemId, params.row.detectedType, integrations)
                            : ''}
                    </Typography>
                </Stack>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            disableColumnMenu: true,
            disableColumnFilter: true,
            disableColumnSelector: true,
            sortable: false,
            headerAlign: 'right',
            align: 'right',
            renderCell: (params) => (
                <IconButton
                    onClick={() => {
                        const newFileList = documentUploaderList.filter(file => file.name !== params.row.name);
                        dispatch(setDocumentUploaderList(newFileList));
                    }}
                >
                    <DeleteOutlined />
                </IconButton>
            ),
        }
    ];

    const renderNoRowsContent = () => (
        <Stack
            alignItems="center"
            sx={{
                mt: 12,
                height: '100%',
                px: 4,
            }}
            {...dragHandlers}
        >
            <Typography variant="h6" sx={{ mt: 1, textAlign: 'center' }}>
                Upload police reports, medical bills, and supporting documents
            </Typography>

            <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                <Tooltip title={hasLinkedCase ? '' : 'Search and select a case from Edit demand details to upload documents'}>
                    <span style={{ display: 'inline-block' }}>

                        <Button
                            startIcon={
                                <img
                                    src={getIntegrationLogoUrl('Clio')}
                                    alt="Clio"
                                    style={{
                                        height: '40px',
                                        width: '50px',
                                        filter: !hasLinkedCase ? 'grayscale(100%)' : 'none'
                                    }}
                                />
                            }
                            variant="outlined"
                            color="secondary"
                            disabled={!hasLinkedCase}
                            sx={{ mt: 2 }}
                            onClick={() => dispatch(setIntegrationDocumentSelectionModalOpen(true))}
                        >
                            Upload from Clio
                        </Button>
                    </span>
                </Tooltip>

                <span style={{ display: 'inline-block' }}>

                    <Button
                        startIcon={<Computer />}
                        variant="outlined"
                        color="secondary"
                        component="label"
                        sx={{
                            mt: 2,
                            height: '50px'
                        }}
                    >
                        Upload from computer
                        <input
                            accept={ACCEPTED_FILE_TYPES}
                            type="file"
                            hidden
                            onChange={handleInputChange}
                            multiple
                        />
                    </Button>
                </span>
            </Stack>

            <Typography variant="caption" sx={{ mt: 2, textAlign: 'center', color: 'text.secondary' }}>
                File types: PDF, JPG/JPEG, PNG, TIF/TIFF, DOC/DOCX
            </Typography>

            <Stack alignItems="center" spacing={2}>
                <CloudUploadOutlined sx={{ fontSize: 50, color: 'secondary.dark' }} />
                <Typography variant="h6" color="secondary.dark">
                    Drop files here
                </Typography>
            </Stack>
        </Stack>
    );

    const tableHeight = (fileListWithId.length + 1) * ROW_HEIGHT + 100;

    return (
        <Box
            sx={{
                position: 'relative',
                height: fileListWithId.length ? `${tableHeight}px` : `calc(100vh - ${heightOffset}px)`,
                cursor: 'default',
            }}
            {...dragHandlers}
        >
            <DataGridPro
                hideFooter
                rows={fileListWithId}
                slots={{
                    noRowsOverlay: () => renderNoRowsContent()
                }}
                columns={columns}
                sx={{
                    height: '100%',
                    '& .MuiDataGrid-root': {
                        border: 'none',
                    },
                }}
            />

            <CustomMuiDialogWrapper
                open={integrationDocumentSelectionModalOpen}
                fullWidth
                maxWidth="md"
            >
                <IntegrationsDocumentSelectionModal />
            </CustomMuiDialogWrapper>
        </Box>
    );
};

export default DocumentUploader;