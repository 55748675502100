import React, { useState } from 'react';
import { Box, Typography, Button, IconButton, Dialog, DialogContent, DialogTitle, DialogActions, Divider, Stack } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Download as DownloadIcon, Folder as FolderIcon, InsertDriveFile as InsertDriveFileIcon, Image as ImageIcon, LocalHospital as LocalHospitalIcon, Description as DescriptionIcon, Close as CloseIcon, SwitchAccountOutlined } from '@mui/icons-material';
import { getIntegrationLogoUrl } from './Helpers';
import { useGetDocumentsForMatterQuery } from '../../services/integrations/integrationsApi';
import { setIntegrationDocumentSelectionModalOpen } from '../../redux/slices/integrationsSlice';
import { setIntegrationSearch } from '../../redux/slices/globalDialogueSlice';
import { setDocumentUploaderList } from '../../redux/slices/demandDomSlice';
import { useDispatch, useSelector } from 'react-redux';

const ClioDocumentsDialog = () => {

    const dispatch = useDispatch();
    const { documentUploaderList } = useSelector((state) => state.DemandDom);
    const { integratedCase: { id: matterId, claimNumber }, integrationData: { integrationId = '', integrationName = '' } } =  useSelector((state) => state.Integrations);

    const { data: documents, error, isLoading } = useGetDocumentsForMatterQuery({ matterId, integrationId });

    const [selectedRows, setSelectedRows] = useState([]);

    const getFileIcon = (folder, fileName) => {  //todo: move to helpers and have David audit
        if (fileName.endsWith('.pdf')) return <InsertDriveFileIcon color="action" />;
        if (fileName.endsWith('.jpeg')) return <ImageIcon color="action" />;
        if (fileName.endsWith('.docx')) return <DescriptionIcon color="action" />;
        return <InsertDriveFileIcon color="action" />;
    };

    const getFolderIcon = (folder) => {  //todo: move to helpers and have David audit
        switch (folder) {
            case 'Medical attachments':
                return <LocalHospitalIcon color="secondary" />;
            default:
                return <FolderIcon color="secondary" />;
        }
    };

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {getFileIcon(params.row.folder, params.row.name)}
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'folder',
            headerName: 'Folder',
            flex: 1,
            renderCell: (params) => (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {getFolderIcon(params.value)}
                    {params.value}
                </Box>
            ),
        },
        {
            field: 'receivedAt',
            headerName: 'Uploaded',
            flex: 1,
            valueFormatter: (params) => {
                return new Date(params.value).toLocaleString();
            }
        },
    ];

    const handleClose = () => {
        setSelectedRows([]);
        dispatch(setIntegrationDocumentSelectionModalOpen(false));

    };

    const handleUpload = (selectedRows) => {
        dispatch(setDocumentUploaderList(
            documents
                .filter(doc => selectedRows
                    .includes(doc.id))
                .map(doc => ({
                    ...doc,
                    type: doc.contentType,
                    isExternalFile: true,
                    externalFileId: doc.id,
                    sourceSystemId: integrationId,
                }))
        ));

        handleClose();
    };

    const handleSearch = () => {
        dispatch(setIntegrationSearch({ open: true, data: { integrationId, integrationName }}));
        handleClose();
    };

    return (
        <>
            <DialogTitle sx={{
                p: 0,
                borderBottom: '1px solid #e0e0e0'
            }}>
                <Box
                    sx={{ px: 3, py: 2, display: 'flex', flexDirection: 'column', gap: 2 }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <img
                            src={getIntegrationLogoUrl(integrationId)}
                            alt={integrationName}
                            style={{ height: 78, width: 78 }}
                        />
                        <Typography
                            variant="h6"
                            sx={{ fontWeight: 500, fontSize: '1.25rem' }}
                        >
                            Documents
                        </Typography>
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography
                                variant="h5"
                                sx={{ fontSize: '1.5rem', fontWeight: 500 }}
                            >
                                {matterId}
                            </Typography>
                            <Button color="secondary" startIcon={<SwitchAccountOutlined sx={{ fontSize: '1.2rem' }} />} onClick={handleSearch}>
                                Select different demand in {integrationName}
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </DialogTitle>

            <DialogContent sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box sx={{height: 500}}>
                    <DataGridPro
                        loading={isLoading}
                        rows={documents || []}
                        columns={columns}
                        checkboxSelection
                        disableRowSelectionOnClick
                        onRowSelectionModelChange={(newSelection) => {
                            setSelectedRows(newSelection);
                        }}
                        sx={{
                            border: 'none',
                            flex: 1,
                            '& .MuiDataGrid-cell': {
                                borderBottom: '1px solid #f0f0f0',
                            },
                        }}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    color="secondary"
                    onClick={handleClose}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    color="secondary"
                    disabled={selectedRows.length === 0}
                    onClick={() => {
                        handleUpload(selectedRows);
                    }}
                >
                    Upload documents
                </Button>
            </DialogActions>
        </>
    );
};

export default ClioDocumentsDialog;