import moment from 'moment';

export const isDemandS3UrlExpired = (demandS3Url = '') => {
  const demandS3UrlExpiryTime = Number(demandS3Url ? demandS3Url.split('Expires=')[1] : 0);
  const currentTime = moment().unix();
  return demandS3UrlExpiryTime < currentTime;
};

export const DOCUMENT_UPLOAD_FILE_TYPES = '.pdf,.jpg,.jpeg,.png,.tif,.tiff,.doc,.docx';

export const DISABLED_EDIT_DOCUMENTS_TOOLTIP_TEXT = 'Demand is currently being processed by Precedent';

export const isDemandOrCoverLetter = (fileDetectedType) =>
  fileDetectedType === 'cover letter' || fileDetectedType === 'demand letter';

export const getDocumentFileByPageNumber = (documentFiles, pageNumber) =>
  documentFiles.find(
    ({ fileEntityData }) => fileEntityData.startPage <= pageNumber && fileEntityData.endPage >= pageNumber
  ) || {};

export const getPageOptions = (documentFiles) => {
  const filesWithoutCoverAndDemandLetter = documentFiles.filter(
    (file) => !isDemandOrCoverLetter(file.fileEntityData.detectedType)
  );
  const startPage = filesWithoutCoverAndDemandLetter[0]?.fileEntityData?.startPage ?? 1;
  const lastPage =
    filesWithoutCoverAndDemandLetter[filesWithoutCoverAndDemandLetter.length - 1]?.fileEntityData?.endPage;

  return Array.from({ length: lastPage - startPage + 1 }, (_, index) => startPage + index);
};

export const getDoucumentSourceDisplayName = (sourceSystemId, detectedType, integrations) => {
  
  if (isDemandOrCoverLetter(detectedType)) {
      return "-";
  }
  else {
    const matched_integration = integrations.find((integration) => integration.integrationId === sourceSystemId);
    if (matched_integration) {
      return matched_integration.displayName;
    }
  }

  return 'Manual upload';
  
};
