import { createSlice } from '@reduxjs/toolkit'

const originalGlobalDialogueState = {
    documentEditingConfirmation: {
        open: false,
        data: {
            title: '',
            text: '',
            confirmText: '',
            cancelText: ''
        }
    },
    savingDemand: {
        open: false,
        data: {
            demandId: '',
            user: {},
            title: '',
            text: ''
        }
    },
    uploadMedicals: {
        open: false,
        data: {
            documentId: '',
            user: {}
        }
    },
    confirmDeleteFile: {
        open: false,
        data: {
            fileName: '',
            fileEntityId: '',
            documentId: '',
            user: {}
        }
    },
    reimportMedicals: {
        open: false,
        data: {
            documentId: '',
            user: {},
        },
    },
    addICDCode: {
        open: false,
        data: {
            documentId: '',
            user: {},
        }
    },
    editICDCode: {
        open: false,
        data: {
            icdSelectedRows: [],
            documentId: '',
            user: {}
        }
    },
    addMriFinding: {
        open: false,
        data: {
            documentId: ''    
        }
    },
    editMRIFinding: {
        open: false,
        data: {
            documentId: '',
            user: {},
        }
    },
    editDemandNotes: {
        open: false,
        isOpenFromInventory: false,
        data: {
            documentId: '',
            user: {},
            initialNote: '',
        }
    },
    editDocumentNames: {
        open: false,
        data: {
            documentId: '',
            user: {}
        }
    },
    addMedicalTreatment: {
        open: false,
        data: {
            documentId: '',
        }
    },
    sendForAIProcessingConfirmation: {
        open: false,
        data: {
            documentId: '',
        },
    },
    attorneyOverrideReview: {
        open: false,
    },
    attorneySend: {
        open: false
    },
    uploadDocumentsDialog: {
        open: false,
        documentId: '',
        data: {
            warningText: 'No data will be extracted from the file(s) that could potentially be used in AI narratives, medical billing charges, diagnosis, etc.',
            toggleText: 'Send for AI processing',
            toggleHelperText: 'Documents will be sent for AI processing and edits will be temporarily disabled.'
        },
    },
    integrationSearch: {
        open: false,
        data: {
            integrationId: '',
            integrationName: '',
        },
    }
}

export const globalDialogueSlice = createSlice({
    name: 'GlobalDialogues',
    initialState: originalGlobalDialogueState,
    reducers: {
        setDocumentEditingConfirmation: (state, action) => { state.documentEditingConfirmation = action.payload },
        setSavingDemand: (state, action) => { state.savingDemand = action.payload },
        setUploadMedicals: (state, action) => { state.uploadMedicals = action.payload },
        setConfirmDeleteFile: (state, action) => { state.confirmDeleteFile = action.payload },
        setReimportMedicals: (state, action) => { state.reimportMedicals = action.payload },
        setAddICDCode: (state, action) => { state.addICDCode = action.payload },
        setEditICDCode: (state, action) => { state.editICDCode = action.payload },
        setAddMriFinding: (state, action) => { state.addMriFinding = action.payload },
        setEditMRIFinding: (state, action) => { state.editMRIFinding = action.payload },
        setEditDemandNotes: (state, action) => { state.editDemandNotes = action.payload },
        setEditDocumentNames: (state, action) => { state.editDocumentNames = action.payload },
        setAddMedicalTreatment: (state, action) => { state.addMedicalTreatment = action.payload },
        setSendForAIProcessingConfirmation: (state, action) => { state.sendForAIProcessingConfirmation = action.payload },
        setAttorneyOverrideReview: (state, action) => { state.attorneyOverrideReview = action.payload },
        setAttorneySend: (state, action) => { state.attorneySend = action.payload },
        setUploadDocumentsDialog: (state, action) => { 
            state.uploadDocumentsDialog = {  //keep the rest of the state and only update the payload
                ...state.uploadDocumentsDialog,
                ...action.payload
            }
        },
        setIntegrationSearch: (state, action) => { state.integrationSearch = action.payload },
        resetGlobalDialogues: () => originalGlobalDialogueState, //use whenever you want to ensure all dialogues are closed
    },
})

export const {
    setDocumentEditingConfirmation,
    setUploadMedicals,
    setConfirmDeleteFile,
    setReimportMedicals,
    resetGlobalDialogues,
    setSavingDemand,
    setAddICDCode,
    setEditICDCode,
    setAddMriFinding,
    setEditMRIFinding,
    setEditDemandNotes,
    setEditDocumentNames,
    setAddMedicalTreatment,
    setSendForAIProcessingConfirmation,
    setAttorneyOverrideReview,
    setAttorneySend,
    setUploadDocumentsDialog,
    setIntegrationSearch,
} = globalDialogueSlice.actions

export default globalDialogueSlice.reducer